@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  -section-bg: linear-gradient(to bottom left,hsla(0,3%,6%,.582),rgba(12,8,24,.904));
  --image-gradient: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.678),
    rgba(12, 10, 22, 0.863)
  );
  filter: brightness(1.2);
}
body{
  overflow-x: hidden;
}

.btn {
  background: linear-gradient(
    92.23deg,
    /* #00838F 21.43%, */
    #7750b6 50.63%,
    #51b9e9 100%,
    #406aff 117.04%
  );
  /* background: #3D0814; */
  box-shadow: 16px 4.42184px 107.23px rgba(255, 86, 246, 0.51);
}
/* px-8 py-4 bg-gradient-to-r from-blue-500 to-purple-500 text-white font-bold rounded-full transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg */

.btn:hover {
  background: linear-gradient(
    92.23deg,
    #5f80f8 21.43%,
    #5dbbe6 50.63%,
    #c560ed 100%,
    #ff56f6 117.04%
  );
  color: rgb(11, 59, 59);
}

.gradient,
.active {
  background: linear-gradient(
    92.23deg,
    #5d7ff8 21.43%,
    #3bace2 50.63%,
    #b936ee 100%,
    #ff56f6 117.04%
  );
}

.active {
  color: #fff;
  padding: 1rem;
  border-radius: 100%;
}

@layer base {
  body {
    @apply font-secondary text-lg leading-8 text-white;
  }
  .h2 {
    @apply font-primary text-[32px] mb-6 tracking-[10%] uppercase;
  }
  .h3 {
    @apply font-primary font-semibold text-[26px] mb-6 leading-[46px];
  }
  .btn {
    @apply rounded-full font-primary text-white font-medium;
  }
  .btn-sm {
    @apply h-[48px] lg:h-[58px] px-6 text-xs lg:text-base;
  }
  .btn-lg {
    @apply h-[56px] lg:h-[70px] px-10 text-sm lg:text-lg;
  }
  .btn-link {
    @apply text-base;
  }
  .text-gradient {
    @apply bg-gradient-to-r text-transparent bg-clip-text from-[#42A6E3] to-[#FF56F6] font-bold font-primary hover:from-[#FF56F6] hover:to-[#42A6E3];
  }
  .section {
    @apply py-16 lg:py-24 lg:h-screen flex items-center min-h-[120vh] bottom-0;
  }
}

@media (min-width: 960px) {
  .section {
    margin-bottom: 20px; /* Adjust the margin value as needed */
    min-height: 100vh;
  }
}
/* Education and Experience Section */
.education-box .education-content::before{
  content: '';
  position: absolute;
  top: 0;
  width: 1rem;
  height: 1rem;
  background-color:#ff56f6;
  border-radius: 50%;
  top: 0;
  left: -0.5rem;
  /* color: #ba40c3; */
}

.sign_img_color{
  filter: invert(10%) sepia(88%) saturate(6980%) hue-rotate(295deg) brightness(99%) contrast(96%);
}



/* Skills Section */

.skills .main-container .container{
  position: relative;
  max-width: 80px;
  width: 100%;
  margin: 0 10px;
  padding: 10px 20px;
  border-radius: 7px;
}

.title-text {
  text-align: center;
  background-color: #01636e;
  color: #fff;
  font-weight: 500;
  border-radius: 50px;
  margin-top: 25px;
  margin-bottom: 8px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 25px;
}

.main-container .container .skill-box {
  width: 100%;
  margin: 25px 0;
}

.skill-box .title{
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: rgb(235, 186, 247);
}

.skill-box .skill-bar{
  height: 8px;
  width: 100%;
  border-radius: 6px;
  margin-top: 6px;
  background: rgba(16, 2, 19, 0.1);
}

.skill-box .skill-per{
  position: relative;
  display: block;
  height: 100%;
  width: 95%;
  border-radius: 6px;
  background: rgb(197, 20, 220);
  animation: progress 0.4s ease-in-out forwards;
  opacity: 0;
}




/* Home Page wave animation */

.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.1s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

/* Footer styling*/

/* --------- */
/* Footer */
/* --------- */
.footer {
  
  bottom: 0 !important;
  padding-top: 10px !important;
  padding-bottom: 8px !important ;
  
}
.footer-copywright {
  text-align: center !important;
}

.footer-body {
  z-index: 1;
  display: flex;
  text-align: center !important;
  justify-content: center;
  min-height: 20vh;
}

@media (max-width: 767px) {
  .footer-copywright {
    text-align: center !important;
  }

  .footer-body {
    text-align: center !important;
    
  }
}

.footer h3 {
  font-size: 1em;
  color: white !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}
.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
  display: flex;
  flex-wrap: row;
}

.blockquote-footer {
  color: #a588c0 !important;
}

.new-bg{
  position: relative;
  z-index: -1;
  background-image:  url(./assets/home-bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  object-fit: contain;
  padding-bottom: 30px !important;
  padding-top: 30px !important;
  filter: contrast(1.3) brightness(0.7);
}

